import React from 'react'

interface Props {}

const ContentSection = (props: Props) => (
    <section className="section skills has-text-centered">
        <div className="container is-narrow">
            <div className="box">
                <div className="content">
                    <div className="columns is-centered">
                        <div className="column">
                            <span className="icon">
                                <i className="fas fa-3x fa-comments"></i>
                            </span>
                            <h2 className="title is-size-2 is-spaced">Tech Talks</h2>
                            <p>
                                Get a booster! Expect a mix of lectures and live-coding, with best
                                practices tips and tricks. Discover ways to speed up React
                                development, and feel free to ask anything.
                            </p>
                        </div>
                        <div className="column">
                            <span className="icon">
                                <i className="fas fa-3x fa-chalkboard-teacher"></i>
                            </span>
                            <h2 className="title is-size-2 is-spaced">Lectures</h2>
                            <p>
                                We value simple content, structure, design patterns, good tools and
                                clean code. We will explore React starting with exploring
                                development ecosystem, new tools and workflows.
                            </p>
                        </div>
                        <div className="column">
                            <span className="icon">
                                <i className="fas fa-3x fa-graduation-cap"></i>
                            </span>
                            <h2 className="title is-size-2 is-spaced">Master classes</h2>
                            <p>
                                Three hours of expert-led advanced content straight to your brain.
                                Expect a mix of lectures and live-coding. Keep learning new things.
                            </p>
                        </div>
                        <div className="column">
                            <span className="icon">
                                <i className="fas fa-3x fa-keyboard"></i>
                            </span>
                            <h2 className="title is-size-2 is-spaced">Workshops</h2>
                            <p className="subtitle">
                                Attend one of the full-day workshops led by our instructors and
                                implement knowledge on a real project on-site.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="columns is-centered">
                <div className="column">
                    <h1 className="title is-spaced">Prerequisites</h1>
                    <h2 className="subtitle is-size-4-desktop">
                        JavaScript/TypeScript and React knowledge is required. Instructions will be
                        sent to attendees in advance.
                    </h2>
                </div>
            </div>
            <br />
        </div>
    </section>
)

export default ContentSection
