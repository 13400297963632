import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

const META_QUERY = graphql`
    query helmetQuery {
        site {
            siteMetadata {
                title
                description
                keywords
                siteUrl
                imageUrl
                fbId
                siteName
            }
        }
    }
`

export default () => (
    <StaticQuery
        query={META_QUERY}
        render={({ site }) => (
            <Helmet>
                <meta name="cache-control" content="public, max-age=0, must-revalidate" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=5"
                />
                <meta name="description" content={site.siteMetadata.description} />
                <meta name="keywords" content={site.siteMetadata.keywords} />
                <title>{site.siteMetadata.title}</title>
                <html lang="en" />
                {/* Google / Search Engine Meta Tags */}
                <meta itemProp="description" content={site.siteMetadata.description} />
                <meta itemProp="image" content={site.siteMetadata.imageUrl} />

                {/* OG props */}
                <meta property="og:url" content={site.siteMetadata.siteUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={site.siteMetadata.title} />
                <meta property="og:description" content={site.siteMetadata.description} />
                <meta property="og:image" content={site.siteMetadata.imageUrl} />
                <meta property="og:site_name" content={site.siteMetadata.siteName} />
                {/* <meta property="fb:app_id" content={site.siteMetadata.fbId} /> */}

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="https://reactweek.dev" />
                <meta name="twitter:title" content="ReactWeek" />
                <meta name="twitter:description" content="All about React in a week." />
                <meta name="twitter:image" content="https://reactweek.dev/img/og_thumbnail.png" />
                <meta name="twitter:image:alt" content="ReactWeek" />

                <link
                    rel="stylesheet"
                    href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
                    integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
                    crossOrigin="anonymous"></link>
            </Helmet>
        )}
    />
)
