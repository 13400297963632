import React from 'react'

interface Props {}

const LecturesSection = (props: Props) => (
    <section className="section is-medium has-text-centered has-border-top">
        <div className="container">
            <h2 className="title is-size-1 is-spaced">Lectures & Master Class Schedule</h2>
            <div className="space"></div>
            <div className="columns">
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-chalkboard-teacher"></i>
                    </span>
                    <h2 className="title is-size-4 is-spaced">Day 1 - Monday</h2>
                    November 30th - Lectures
                    <div>---</div>
                    <div>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        15:30h - 16:30h
                        <p className="title is-4">React 17, Ecosystem</p>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        17:00h - 18:00h
                        <p className="title is-4">Micro-frontend architecture</p>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        18:30h - 19:30h
                        <p className="title is-4">Monorepo project setup with TypeScript</p>
                    </div>
                </div>
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-chalkboard-teacher"></i>
                    </span>
                    <h2 className="title is-size-4 is-spaced">Day 2 - Tuesday</h2>
                    December 1st - Lectures
                    <div>---</div>
                    <div>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        15:30h - 16:30h
                        <p className="title is-4">State management - Recoil</p>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        17:00h - 18:00h
                        <p className="title is-4">State management - ReactQuery</p>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        18:30h - 19:30h
                        <p className="title is-4">State management - ReactSWR</p>
                    </div>
                </div>
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-graduation-cap"></i>
                    </span>
                    <h2 className="title is-size-4 is-spaced">Day 3 - Wednesday</h2>
                    December 2nd - Master classes
                    <div>---</div>
                    <div>
                        <span className="tag is-warning is-medium">Master Class</span>
                        <br />
                        09:30h - 11:30h
                        <p className="title is-4">Starting ReactNative development</p>
                        <span className="tag is-warning is-medium">Master Class</span>
                        <br />
                        12:00h - 15:00h
                        <p className="title is-4">Modern React Development</p>
                    </div>
                </div>
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-graduation-cap"></i>
                    </span>
                    <h2 className="title is-size-4 is-spaced">Day 4 - Thursday</h2>
                    December 3rd - Master classes
                    <div>---</div>
                    <div>
                        <span className="tag is-warning is-medium">Master Class</span>
                        <br />
                        10h - 13h
                        <p className="title is-4">Testing React components</p>
                    </div>
                    <div>
                        <span className="tag is-warning is-medium">Master Class</span>
                        <br />
                        16:00h - 19:00h
                        <p className="title is-4">E2E Testing with Cypress</p>
                    </div>
                </div>
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-chalkboard-teacher"></i>
                    </span>
                    <h2 className="title is-size-4 is-spaced">Day 5 - Friday</h2>
                    December 4th - Lectures
                    <div>---</div>
                    <div>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        10:00h - 11:00h
                        <p className="title is-4">React and GraphQL (Apollo)</p>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        11:30h - 12:30h
                        <p className="title is-4">Styling and design system in React</p>
                        <span className="tag is-success is-medium">Lecture</span>
                        <br />
                        13:00h - 14:00h
                        <p className="title is-4">Public Q&A with the speakers</p>
                    </div>
                </div>
                ´
            </div>
        </div>
    </section>
)

export default LecturesSection
