import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const instructorsQuery = graphql`
    {
        marko: imageSharp(fixed: { originalName: { eq: "marko-conf.jpg" } }) {
            resize {
                src
            }
        }
        nenad: imageSharp(fixed: { originalName: { eq: "nenad.jpg" } }) {
            resize {
                src
            }
        }
        nikola: imageSharp(fixed: { originalName: { eq: "nikola-js.jpg" } }) {
            resize {
                src
            }
        }
    }
`

interface Props {}

const InstructorsSection = (props: Props) => {
    const data = useStaticQuery(instructorsQuery)

    return (
        <section className="section testimonials is-medium is-white has-text-centered has-border-top">
            <div className="container is-narrow">
                <h2 className="title is-size-1 is-spaced">Your instructors</h2>
                <h3 className="subtitle is-size-4-desktop">
                    People who are going to work with you
                </h3>
                <div className="columns is-centered is-4">
                    <div className="column is-third">
                        <div className="carousel-item">
                            <div className="testimonial-block">
                                <img
                                    className="avatar"
                                    src={data.marko.resize.src}
                                    alt="marko-arsic-img"
                                />
                                <h1 className="title is-size-2">Marko Arsić</h1>
                                <h2 className="subtitle is-size-4">
                                    Organizer / Independent Tech Consultant / Lecturer
                                </h2>
                                <p className="quote">
                                    A polyglot developer deeply interested in high performance,
                                    software architecture and solving complex problems. Coding,
                                    learning, &amp; leveling up one day at a time.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column is-third">
                        <div className="testimonial-block">
                            <img
                                className="avatar"
                                src={data.nenad.resize.src}
                                alt="nenad-bugaric-img"
                            />
                            <h1 className="title is-size-2">Nenad Bugarić</h1>
                            <h2 className="subtitle is-size-4">Team Lead / Sr Software Engineer</h2>
                            <p className="quote">
                                Full stack web developer on a mission to make complex systems as
                                simple as possible! Trying to learn something new every day and
                                always happy to share knowledge.
                            </p>
                        </div>
                    </div>
                    <div className="column is-third">
                        <div className="testimonial-block">
                            <img
                                className="avatar"
                                src={data.nikola.resize.src}
                                alt="nikola-djuza-img"
                            />
                            <h1 className="title is-size-2">Nikola Đuza</h1>
                            <h2 className="subtitle is-size-4">Sr Software Engineer / Writer</h2>
                            <p className="quote">
                                Nikola helps developers improve their productivity by sharing
                                pragmatic advice & applicable knowledge on JavaScript and Ruby. He
                                provides a better understanding of the two languages and how to
                                properly write tests.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InstructorsSection
