import React from 'react'

interface Props {}

const Footer = (props: Props) => (
    <footer className="section is-primary is-small has-text-centered">
        <div className="container is-narrow">
            <div className="logo">
                <h1 className="title is-size-2">ReactWeek 2020</h1>
            </div>
            <p>by Hype Tech Education</p>
            <div className="columns is-centered">
                <div className="column is-one-third">
                    <h1 className="title is-size-4-touch">
                        Coding, learning, &amp; leveling up one day at a time.
                    </h1>
                </div>
            </div>
            <div className="copyright">
                <span className="icon has-white-text">
                    <i className="far fa-copyright"></i>
                </span>
                twentytwenty
            </div>
        </div>
    </footer>
)

export default Footer
