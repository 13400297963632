import React from 'react'

import './style.scss'

import SEO from './SEO'
import NavBar from './NavBar'
import Footer from './Footer'
import IntroSection from './IntroSection'
import WhereSection from './WhereSection'
import ContentSection from './ContentSections'
import LecturesSection from './LecturesSection'
import WorkshopsSection from './WorkshopsSection'
import PriceSection from './PriceSection'
import InstructorsSection from './InstructorsSection'
import SponsorsBadge from './SponsorsBadge'
import QuestionsSection from './QuestionsSection'
import TechTalksSection from './TechTalksSection'

const MainLayout = () => (
    <div>
        <SEO />
        <NavBar />
        <IntroSection />
        <WhereSection />
        <ContentSection />
        <TechTalksSection />
        <LecturesSection />
        <WorkshopsSection />
        <InstructorsSection />
        <PriceSection />
        {/* <QuestionsSection /> */}
        <SponsorsBadge />
        <Footer />
    </div>
)

export default MainLayout
