import React from 'react'

interface Props {}

const NavBar: React.FC<Props> = props => (
    <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">
        <div className="container">
            <div className="navbar-brand">
                <div className="navbar-item title">
                    <h1 className="title">
                        <strong className="has-text-info">ReactWeek</strong> 2020
                    </h1>
                </div>
            </div>
            <div className="navbar-menu" id="navMenu">
                <div className="navbar-start"></div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <a
                            className="button is-primary is-outlined is-rounded"
                            href="mailto:contact@reactweek.dev"
                            target="_blank"
                            rel="noreferrer">
                            Get in touch
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
)

export default NavBar
