import React from 'react'

interface Props {}

const IntroSection = (props: Props) => (
    <section className="hero is-fullheight-with-navbar is-white has-text-centered">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-centered is-half">
                    <div className="column">
                        <h1 className="title is-spaced is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                            A week-long program dedicated to{' '}
                            <strong className="has-text-info">React</strong> and its ecosystem{' '}
                            <strong className="has-text-info">for experienced</strong> developers
                        </h1>
                        <h2 className="subtitle is-size-3-desktop">
                            The talks, lectures, master classes and workshops will feature advanced
                            topics aimed for junior and medior software developers and consultants.
                        </h2>
                    </div>
                    <div className="column is-half">
                        <div className="circles">
                            <div></div>
                            <div></div>
                            <div></div>
                            <span></span>
                        </div>
                    </div>
                </div>
                <br />
                <div className="columns is-centered">
                    <div className="column">
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="title is-1">40+ hours</p>
                                <p className="heading">of latest knowledge</p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="title is-1">9 lectures</p>
                                <p className="heading">of cutting edge technology</p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="title is-1">4 master class</p>
                                <p className="heading">of expert advices from experience</p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="title is-1">2 workshops</p>
                                <p className="heading">with hands-on experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default IntroSection
