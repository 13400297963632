import React from 'react'

interface Props {}

const SponsorsBadge = (props: Props) => (
    <section className="section call-to-action is-primary has-text-centered">
        <div className="container is-narrow">
            <div className="box">
                <div className="columns level">
                    <div className="column level-item">
                        <h1 className="title">Sponsor us</h1>
                    </div>
                    <div className="column level-item">
                        <p>
                            Interested in partnership or sponsoring us? We should meet and chat
                            about that.
                        </p>
                    </div>
                    <div className="column level-item">
                        <a
                            className="button is-primary is-outlined is-rounded is-medium"
                            rel="noreferrer"
                            target="_blank"
                            href="mailto:contact@reactweek.dev">
                            Get in touch
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default SponsorsBadge
