import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const companyImagesQuery = graphql`
    {
        hytLogo: imageSharp(fixed: { originalName: { eq: "hyt-logo-v1.png" } }) {
            resize {
                src
            }
        }
        devClubImage: imageSharp(fixed: { originalName: { eq: "devclub-blue.png" } }) {
            resize {
                src
            }
        }
        tarabaImage: imageSharp(fixed: { originalName: { eq: "taraba.png" } }) {
            resize {
                src
            }
        }
        bitImage: imageSharp(fixed: { originalName: { eq: "bit-logo.png" } }) {
            resize {
                src
            }
        }
        nsImage: imageSharp(fixed: { originalName: { eq: "nsjs-logo.png" } }) {
            resize {
                src
            }
        }
        enjoyingImage: imageSharp(fixed: { originalName: { eq: "enjoying.png" } }) {
            resize {
                src
            }
        }
        vegaImage: imageSharp(fixed: { originalName: { eq: "vegait.png" } }) {
            resize {
                src
            }
        }
    }
`

interface Props {}

const PriceSection = (props: Props) => {
    const data = useStaticQuery(companyImagesQuery)

    console.log('data', data)
    return (
        <>
            <section className="section is-medium is-primary has-text-centered is-long-ish">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-three-fifths">
                            <h1 className="title is-spaced is-size-2-desktop is-size-3-mobile">
                                Price
                            </h1>
                            <h2 className="subtitle is-size-4-desktop">
                                Organizing this kind of event demands time commitment, preparation
                                and resources. Depending of type of the ticket, price includes
                                access to related content.
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section startups has-text-centered">
                <div className="container is-narrow">
                    <div className="startup-grid">
                        <div className="columns is-centered level">
                            <div className="column level-item">
                                <div className="box">
                                    <h1 className="title is-spaced is-size-3-desktop is-size-4-mobile">
                                        Lectures Pass
                                    </h1>
                                    <br />
                                    <p>
                                        Access to all lectures and tech talks for the whole week.
                                        11+ hours of lectures and live coding sessions.
                                    </p>
                                    <p className="title is-size-2 is-spaced">
                                        99
                                        <span className="icon">
                                            <i className="fas fa-euro-sign"></i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="column level-item">
                                <div className="box">
                                    <h1 className="title is-spaced is-size-3-desktop is-size-4-mobile">
                                        Workshop
                                    </h1>
                                    <br />
                                    <p>
                                        Skip all the talks and focus only on practical tasks. We
                                        offer all level workshops with same price per workshop.
                                    </p>
                                    <p className="title is-size-2 is-spaced">
                                        90
                                        <span className="icon">
                                            <i className="fas fa-euro-sign"></i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="column level-item">
                                <div className="box">
                                    <h1 className="title is-spaced is-size-3-desktop is-size-4-mobile">
                                        Master classes
                                    </h1>
                                    <br />
                                    <p>
                                        Get access to all 4 master classes to catch up fast new
                                        things with three hours of expert-led content.
                                    </p>
                                    <p className="title is-size-2 is-spaced">
                                        150
                                        <span className="icon">
                                            <i className="fas fa-euro-sign"></i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            Single Master Class pass is also available for a price of 50e.
                            <br />
                            Master classes pass holders get a 15% discount on workshops. We also
                            offer a 20% discount for group (3+) purchases.
                            <p>
                                Most of ReactWeek courses are held in Serbian language. Some free
                                sponsor lectures and talks can be in English if requested.
                            </p>
                        </div>
                    </div>
                    <div className="client-grid">
                        <h1 className="title is-spaced is-size-3-desktop is-size-4-mobile">
                            Powered by
                        </h1>
                        <div className="columns is-multiline is-centered is-mobile">
                            <div className="column is-half-mobile is-one-quarter-tablet">
                                <figure className="image ">
                                    <img
                                        className="client-logo"
                                        src={data.hytLogo.resize.src}
                                        alt="hype-tech-img"
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="space"></div>
                        <h1 className="title is-spaced is-size-4-desktop is-size-5-mobile">
                            SPONSORS
                        </h1>
                        <div className="columns is-centered is-mobile">
                            <div className="column is-half-mobile is-one-quarter-tablet mr-5">
                                <figure className="image is-2x1">
                                    <img
                                        className="client-logo"
                                        src={data.enjoyingImage.resize.src}
                                        alt="enjoying-img"
                                    />
                                </figure>
                            </div>
                            <div className="column is-1"></div>
                            <div className="column is-half-mobile is-one-quarter-tablet">
                                <figure className="image is-2x1">
                                    <img
                                        className="client-logo"
                                        src={data.vegaImage.resize.src}
                                        alt="vegait-img"
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="space"></div>
                        <h1 className="title is-spaced is-size-5-desktop is-size-5-mobile">
                            FRIENDS
                        </h1>
                        <div className="columns is-multiline is-centered is-mobile">
                            <div className="column is-half-mobile is-one-quarter-tablet">
                                <figure className="image is-2x1">
                                    <img
                                        className="client-logo"
                                        src={data.devClubImage.resize.src}
                                        alt="devclub-img"
                                    />
                                </figure>
                            </div>
                            <div className="column is-half-mobile is-one-quarter-tablet">
                                <figure className="image is-2x1">
                                    <img
                                        className="client-logo"
                                        src={data.tarabaImage.resize.src}
                                        alt="taraba-img"
                                    />
                                </figure>
                            </div>
                            <div className="column is-half-mobile is-one-quarter-tablet">
                                <figure className="image is-2x1">
                                    <img
                                        className="client-logo"
                                        src={data.bitImage.resize.src}
                                        alt="bit-img"
                                    />
                                </figure>
                            </div>
                            <div className="column is-1 is-half-mobile is-one-quarter-tablet">
                                <figure className="image is-64x64">
                                    <img
                                        className="client-logo"
                                        src={data.nsImage.resize.src}
                                        alt="nsjs-img"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PriceSection
