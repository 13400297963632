import React from 'react'

import './css/main.css'
import './css/style.css'

import MainLayout from 'components/MainLayout'

const IndexPage = () => <MainLayout />

export default IndexPage
