import React from 'react'

interface Props {}

const TechTalksSection = (props: Props) => (
    <section className="section is-primary is-medium has-text-centered has-border-top">
        <div className="container">
            {/* TECH TALKS */}
            <h2 className="title is-size-1 is-spaced">Free Tech Talks</h2>
            <div className="space"></div>

            <div className="columns">
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-comments"></i>
                    </span>
                    <div>
                        <p className="title">The IDE for the web, VSCode can do that</p>

                        <p>
                            <a
                                className="button is-primary is-inverted is-large is-outlined is-rounded"
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.youtube.com/watch?v=oZ-6SsiA5vI">
                                <span className="icon">
                                    <i className="fab fa-1x fa-youtube"></i>
                                </span>
                                <span>Watch on YouTube</span>
                            </a>
                        </p>

                        <p className="body is-size-5">
                            All the best things about Visual Studio Code that nobody ever bothered
                            to tell you. Be careful, after this lecture you will be rethinking use
                            of your IDE of choice and if you are not ready for that this lecture is
                            probably not for you
                        </p>
                    </div>
                </div>
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-comments"></i>
                    </span>
                    <div>
                        <p className="title">Avoiding common React mistakes</p>
                        <p>
                            <a
                                className="button is-primary is-inverted is-large is-outlined is-rounded"
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.youtube.com/watch?v=rRHq_UUPjPE">
                                <span className="icon">
                                    <i className="fab fa-1x fa-youtube"></i>
                                </span>
                                <span>Watch on YouTube</span>
                            </a>
                        </p>
                        <p className="body is-size-5">
                            There are a lot of things that can go wrong with the way we use React.
                            Here, we will talk about some useful best-practices along the way and
                            some extremely common mistakes made by beginners.
                        </p>
                    </div>
                </div>
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-comments"></i>
                    </span>
                    <div>
                        <p className="title">Starting React Native development</p>
                        <p>
                            <a
                                className="button is-primary is-inverted is-large is-outlined is-rounded"
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.youtube.com/watch?v=Tt3ChLSUPtI">
                                <span className="icon">
                                    <i className="fab fa-1x fa-youtube"></i>
                                </span>
                                <span>Watch on YouTube</span>
                            </a>
                        </p>
                        <p className="body is-size-5">
                            Many different kinds of people use React Native: from advanced
                            Android/iOS developers to React beginners, to people getting started
                            programming for the first time in their career. This lecture is for all
                            learners, no matter their experience level or background.
                        </p>
                    </div>
                </div>
            </div>
            <a
                className="button is-primary is-inverted is-large is-outlined is-rounded"
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCAjXfLeJ2qXk2hA3gujd5Ig">
                <span className="icon">
                    <i className="fab fa-1x fa-youtube"></i>
                </span>
                <span>Visit YouTube channel</span>
            </a>
        </div>
    </section>
)

export default TechTalksSection
