import React from 'react'

interface Props {}

const WorkshopsSection = (props: Props) => (
    <section className="section clients is-primary is-medium is-white has-text-centered has-border-top">
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-full">
                    <h2 className="title is-size-1 is-spaced">Workshops Schedule</h2>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-keyboard"></i>
                    </span>
                    <h2 className="title is-size-4 is-spaced">Day 6 - Saturday</h2>
                    <div className="columns">
                        <div className="column">
                            <p className="body is-size-4">December 5th | 14:00h - 20:00h</p>
                            <p className="title">Full Stack React with headless CMS</p>
                            <span className="tag is-light is-medium">Workshop</span>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="divider"></div>
                <div className="column">
                    <span className="icon">
                        <i className="fas fa-3x fa-keyboard"></i>
                    </span>
                    <h2 className="title is-size-4 is-spaced">Day 7 - Sunday</h2>
                    <div className="columns">
                        <div className="column">
                            <p className="body is-size-4">December 6th | 14:00h - 20:00h</p>
                            <p className="title">AWS Amplify Serverless React w/ TypeScript</p>
                            <span className="tag is-light is-medium">Workshop</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default WorkshopsSection
