import React from 'react'

interface Props {}

const WhereSection = (props: Props) => (
    <section className="section is-primary has-text-centered is-long">
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-three-fifths">
                    <h3 className="heading is-spaced is-size-3-desktop is-size-4-mobile">
                        When and where?
                    </h3>
                    <h2 className="subtitle is-size-3-desktop">Nov 30th - Dec 6th, 2020</h2>
                    <h1 className="title is-spaced is-size-1-desktop is-size-4-mobile">
                        online event
                    </h1>
                </div>
            </div>
        </div>
    </section>
)

export default WhereSection
